import React from "react"

import SEO from "../components/seo"
import { graphql, PageProps } from "gatsby"
import Image from "../components/image"
import Header from "../components/header"
import { ProductHuntBanner } from "../components/producthuntbanner"

const PrivacyPage = ({ data }: PageProps<Queries.PrivacyPageQuery>) => (
  <>
    <SEO title="Your bookmarks, by date, with thumbnails and instant search" canonical="/privacy" />
    <>
      <ProductHuntBanner />
      <Header
        webStoreImage={
          <Image
            image={data.webStore?.childImageSharp?.gatsbyImageData}
            alt="Available in the Chrome Web Store" />
        }
      />
      <main>
        <div className="container" style={{ marginBottom: "8rem" }}>
          <h1>Privacy policy</h1>
          I built the Rewind app as a Free app. This SERVICE is provided by me
          at no cost and is intended for use as is.
          <br />
          <br />
          This page is used to inform visitors regarding my policies with the
          collection, use, and disclosure of Personal Information if anyone
          decided to use my Service.
          <br />
          <br />
          <h2>Usage of your information</h2>
          I will not use or share your information with anyone, however, the
          extension sends an individual request for each of the bookmark URLs
          that are displayed on Rewind to these apps:
          <br />
          <br />
          <ul>
            <li>
              https://www.robothumb.com to display thumbnails for your bookmarks
            </li>
            <li>
              https://allorigins.win to allow the extension to request your
              bookmarks preview picture from their Open Graph metadata
            </li>
          </ul>
          <strong>
            They are obligated not to disclose or use this information for any
            other purpose.
          </strong>
          <br />
          I pledge to replace these services by open source solutions in the
          future.
          <br />
          <br />
          The extension also relies on https://fonts.googleapis.com to display
          nice looking fonts.
          <br />
          <br />
          I do not knowingly collect personally identifiable information from
          children under (or over) 13.
          <br />
          <br />
          <h2>Storage of your information</h2>
          The extension does not rely on cookies, I do not use any analytics app
          or service (I don't care, frankly).
          <br />
          I use your browser's storage to keep a list of the bookmarks you want
          to hide.
          <br />
          The information that the extension uses (your bookmark data and list
          of hidden links) will be retained on your device and is not collected
          by me in any way.
          <br />
          <br />
          <h2>Third-party links</h2>
          This Service displays the links you have saved to your bookmarks.
          <br />
          If you click on a third-party link, you will be directed to that site.
          <br />
          Note that these external sites are not operated by me.
          <br />
          Therefore, I strongly advise you to review the Privacy Policy of these
          websites.
          <br />
          I have no control over and assume no responsibility for the content,
          privacy policies, or practices of any third-party sites or services.
          <br />
          <br />
          <h2>Security of your information</h2>
          Remember that no method of transmission over the internet, or method
          of electronic storage is 100% secure and reliable, and I cannot
          guarantee its absolute security.
          <br />
          <br />
          <h2>Changes to This Privacy Policy</h2>
          I may update our Privacy Policy from time to time.
          <br />
          Thus, you are advised to review this page periodically for any
          changes.
          <br />
          <br />
          This policy is effective as of 2020-05-10
          <br />
          <br />
          If you have any questions or suggestions about my Privacy Policy, feel
          free to contact me{" "}
          <a href="https://twitter.com/j_____________n">on Twitter</a>
        </div>
      </main>
    </>
  </>
)

export const query = graphql`
    query PrivacyPage {
        webStore: file(relativePath: {eq: "chrome-web-store-button@2x.png"}) {
            childImageSharp {
                gatsbyImageData(width: 196, quality: 100, placeholder: NONE, layout: FIXED)
            }
        }
    }
`

export default PrivacyPage
